<template>
  <div class="tasks-page">
    <el-row
      class="tasks-list"
      :gutter="20"
      style="min-height: 400px"
      v-loading="loading"
      element-loading-background="rgba(0, 0, 0, 0)"
    >
      <el-col :span="8" v-for="t in tasksList" :key="t.id">
        <el-card shadow="hover">
          <div class="card-right--title">
            <div>{{ t.number }}</div>
            <div class="text-md text-black-light">{{ t.platform }}</div>
          </div>
          <div
            class="card-right--sub-title mt-10 title-md text-black text-bold"
          >
            {{ t.title }}
          </div>
          <div class="card-right--title-tip">
            <div>{{ $t("time_about") }}{{ t.loi }}{{ $t("minutes") }}</div>
            <div>
              {{ $t("task_reward") }}：<span class="color-orange"
                >{{ t.minPoints }}-{{ t.maxPoints }}{{ $t("gold") }}</span
              >
            </div>
          </div>
          <div class="card-right--footer">
            <el-tag v-if="t.ir" effect="dark" size="small" type="success"
              >{{ $t("success_rate") }}{{ t.ir + "%" }}</el-tag
            >
            <el-button type="primary" size="small" @click="takeIn(t)" round>{{
              t.loop
                ? $t("immediately_take_in_mutli")
                : $t("immediately_take_in")
            }}</el-button>
            <el-button
              size="small"
              plain
              round
              v-if="t.allowedShare"
              @click="setDialogVisible({ shareSurvey: t.encryptId })"
              >{{ $t("share") }}</el-button
            >
            <el-link v-if="t.allowedHide" @click="hiddenTask(t)">{{
              $t("hidden")
            }}</el-link>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<style src="./index.scss" lang="scss"></style>
<script>
import { mapGetters, mapActions } from "vuex";
import { filter, isEmpty } from "lodash-es";

export default {
  data() {
    return {
      dialogVisible: false,
      tasksList: [],
      loading: false,
      keepFetching: true
    };
  },
  watch: {
    userInfo: "checkUserInfo"
  },
  beforeDestroy() {
    this.keepFetching = false;
  },
  async mounted() {
    if (!this.account.id) return;
    try {
      this.loading = true;

      const promises = [
        `/api/v1/Accounts/${this.account.id}/SurveysOfLucid`,
        `/api/v1/Accounts/${this.account.id}/SurveysOfSSI`,
        `/api/v1/Accounts/${this.account.id}/IndividualSurveys`,
        `/api/v1/Accounts/${this.account.id}/SurveysOfGMO`,
        `/api/v1/Accounts/${this.account.id}/SurveysOfCint`,
        `/api/v1/Accounts/${this.account.id}/SurveysOfYours`
      ];
      let index = 0;
      const doPromise = () => {
        if (index >= promises.length) return;
        this.$http
          .get(promises[index])
          .then(res => {
            if (index === 2) {
              res.data = res.data.map(r => ({ ...r, isIndividual: true }));
            }
            this.tasksList = [...this.tasksList, ...res.data];
            index += 1;
            if (this.keepFetching) {
              doPromise();
            }
            this.loading = false;
          })
          .catch(() => {
            index += 1;
            if (this.keepFetching) {
              doPromise();
            }
            this.loading = false;
          });
      };
      doPromise();
    } catch (e) {
      this.loading = false;
    }
  },
  created() {
    if (this.account && this.account.isBlacklist) {
      return this.$router.push(
        { path: "/" },
        () => {},
        () => {}
      );
    }
    this.checkUserInfo();
  },
  methods: {
    ...mapActions(["setDialogVisible"]),

    checkUserInfo() {
      if (
        !isEmpty(this.userInfo) &&
        (!this.userInfo.birthday || !this.userInfo.gender)
      ) {
        this.$message.warning(this.$t("userinfo_tip"));
        return this.$router.push({
          path: "/center/setting",
          query: { tab: "user" }
        });
      }
    },
    handleClose() {},
    takeIn(task) {
      this.keepFetching = false;
      if (task.isIndividual) {
        return this.$router.push(`/pages/survey/${task.encryptId}`);
      }
      window.open(task.surveyLink);
    },
    hiddenTask(survery) {
      this.$confirm(this.$t("task_hidden_tip"), this.$t("tip"), {
        confirmButtonText: this.$t("tip"),
        cancelButtonText: this.$t("cancel"),
        type: "warning"
      })
        .then(() => {
          this.$http
            .post(`/api/v1/Accounts/${this.account.id}/HiddenSurvey`, null, {
              params: {
                surveyID: survery.encryptId,
                surveySeed: survery.surveySeed
              }
            })
            .then(res => {
              if (res.status === 200) {
                this.$message.success(this.$t("hidden_success"));
                this.tasksList = filter(
                  this.tasksList,
                  t => t.id !== survery.id
                );
              } else {
                this.$message.error(res.statusText);
              }
            })
            .catch(() => {
              this.$message.error(this.$t("hidden_fail"));
            });
        })
        .catch(() => {});
    }
  },
  computed: {
    ...mapGetters(["account", "userInfo"])
  }
};
</script>
